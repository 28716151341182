import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c987d78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  key: 0,
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = {
  key: 0,
  class: "badge text-uppercase badge-light-danger"
}
const _hoisted_13 = {
  key: 1,
  class: "badge text-uppercase badge-light-success"
}
const _hoisted_14 = { class: "text-nowrap" }
const _hoisted_15 = ["disabled", "onClick"]
const _hoisted_16 = { class: "svg-icon svg-icon-2" }
const _hoisted_17 = {
  key: 1,
  class: "spinner-border spinner-border-sm align-middle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-250px ps-14",
            placeholder: _ctx.t('common.search')
          }, null, 40, _hoisted_6), [
            [_vModelText, _ctx.search]
          ]),
          _withDirectives(_createElementVNode("span", {
            class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
            style: {"cursor":"pointer"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
          }, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
          ], 512), [
            [_vShow, _ctx.search != '']
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.checkedCompanys.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("button", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
                ]),
                _createTextVNode(_toDisplayString(_ctx.t("common.filter")), 1)
              ]),
              _createVNode(_component_FilterDropdown, {
                onSubmitFilter: _ctx.handleFilter,
                onResetFilter: _ctx.handleFilterReset
              }, null, 8, ["onSubmitFilter", "onResetFilter"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_MBDatatable, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true,
        "current-page": _ctx.currentPage,
        total: _ctx.total,
        "rows-per-page": _ctx.pageSize,
        order: "desc",
        onCurrentChange: _ctx.onCurrentPageChange,
        onItemsPerPageChange: _ctx.onRowsPerPageChange,
        onSort: _ctx.onColumnSort
      }, {
        "cell-created_at": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.formatDateTime(item.created_at)), 1)
        ]),
        "cell-invoice_number": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.invoice_number), 1)
        ]),
        "cell-track_number": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.track_number), 1)
        ]),
        "cell-status": _withCtx(({ row: item }) => [
          (item.status == 20)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.t("common.invalid")), 1))
            : (item.status == 10)
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.t("common.valid")), 1))
              : _createCommentVNode("", true)
        ]),
        "cell-actions": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_14, [
            _withDirectives((_openBlock(), _createElementBlock("button", {
              type: "button",
              class: "btn btn-light-primary me-3 flex",
              disabled: item.downloadDisabled,
              onClick: ($event: any) => (_ctx.fileExport(item.id, item))
            }, [
              _createElementVNode("span", _hoisted_16, [
                (!item.downloadDisabled)
                  ? (_openBlock(), _createBlock(_component_inline_svg_icon, {
                      key: 0,
                      src: "media/icons/duotune/arrows/arr091.svg"
                    }))
                  : (_openBlock(), _createElementBlock("span", _hoisted_17))
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.t("common.download")), 1)
            ], 8, _hoisted_15)), [
              [_directive_auth, { auth: ['export'] }]
            ])
          ])
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
    ])
  ]))
}